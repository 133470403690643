import React from "react";
import Layout from "../../../components/Layout";
import { useStaticQuery, graphql } from "gatsby";
import BlogsHeroGrid from "../../../components/BlogsHeroGrid";
import ArticleGrid from "../../../components/ArticleGrid";
import { useState } from "react";
import EmailUsBlock from "../../../components/EmailUsBlock";
import FilterCategory from "../../../components/FilterCategory";
import SingleBlog from "../../../components/SingleBlog";
import Seo from "../../../components/seo.jsx";
import ogIMage from "../../../images/og.png";

const ListOfBlogs = ({ location }) => {
    const blogList = useStaticQuery(graphql`
        {
            allContentfulBlogs {
                nodes {
                    title
                    description {
                        description
                    }
                    featured
                    readTime
                    slug
                    author
                    blogImage {
                        gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                    }
                    categories {
                        categoryType
                        slug
                    }
                }
            }
        }
    `);

    const featuredBlogs = blogList.allContentfulBlogs.nodes.filter((node) => node.featured);
    var featuredArticles = blogList.allContentfulBlogs.nodes.filter((node) => !node.featured);

    const blogCategory = blogList.allContentfulBlogs.nodes.map((blog) => {
        return blog.categories.map((param) => {
            return param;
        });
    });

    const blogArr = blogCategory.map(([lat]) => lat);
    const seen = new Set();
    const uniqueCategory = blogArr.filter((el) => {
        const duplicate = seen.has(el.categoryType);
        seen.add(el.categoryType);
        return !duplicate;
    });

    const [blogData, setblogData] = useState(featuredArticles);

    return (
        <Layout>
            <Seo
                title={"Blogs | Sedin"}
                description={
                    "Check out our latest blogs, insights on AI, GenAI, GCC, data analytics, Salesforce, ECM, EAM, Wiring Harness, CAD & technologies. Explore here to learn new things. "
                }
                image={`https://sedintechnologies.com${ogIMage}`}
                url={location.href}
            />
            <section className="blogs-hero">
                <div className="container container--first-v3">
                    {featuredBlogs.length > 1 ? (
                        <>
                            <div className="blogs-hero__text">
                                <h1 className="text-h1 text-primary-clr">The Sedin blog</h1>
                            </div>
                            <FilterCategory uniqueCategory={uniqueCategory} />
                            <BlogsHeroGrid blogsData={featuredBlogs} />
                        </>
                    ) : (
                        <SingleBlog blogsData={featuredBlogs} />
                    )}
                </div>
            </section>
            {featuredArticles.length > 0 ? (
                <section className="article">
                    <div className="container container--mid-v2 ">
                        <ArticleGrid featuredArticles={blogData} />
                    </div>
                </section>
            ) : (
                ""
            )}
            <section className="email">
                <div className="container container--last-v2">
                    <EmailUsBlock />
                </div>
            </section>
        </Layout>
    );
};

export default ListOfBlogs;
