import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const SingleBlog = ({ blogsData }) => {
    return (
        <>
            <section className="single-blog">
                <p className="text-p3 blog-text text-clr-secondary text-fw-medium gap-2x">Blog</p>
                <h1 className="text-h1 blog-header text-fw-medium text-clr-primary">
                    The latest industry news, interviews, technologies, and resources.
                </h1>
                {blogsData.map((el, i) => (
                    <>
                        <GatsbyImage image={el.blogImage.gatsbyImageData} alt={el.title} key={i} />
                        <div className="category gap-05x">
                            {el.categories.map((category, index) => (
                                <Link
                                    to={`/blog/${category.slug}/`}
                                    key={index}
                                    className="text-decor-none text-p5 text-clr-primary"
                                >
                                    {category.categoryType}
                                </Link>
                            ))}
                        </div>
                        <Link to={`/blog/${el.slug}/`} className="text-decor-none">
                            <h2 className="text-fw-medium title text-clr-primary text-h2 ">
                                {el.title}
                            </h2>
                        </Link>
                        <p className="text-p4 text-clr-secondary text-fw-regular gap-05x">
                            {el.description.description}
                        </p>
                        <div className="author-time">
                            <p className="text-p5 text-clr-secondary text-fw-medium">{el.author}</p>
                            <p className="text-p5 text-clr-secondary text-fw-medium">
                                {el.readTime}
                            </p>
                        </div>
                    </>
                ))}
            </section>
        </>
    );
};

export default SingleBlog;
