import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const BlogsHeroGridV2 = ({ featuredData }) => {
    return featuredData.map(
        (param, i) =>
            i > 0 && (
                <div key={i} className="blog-hero__grid-item-right-col-wrap__content flex">
                    <div className="blog-hero__grid-item-right-col-wrap__content-image">
                        <GatsbyImage
                            image={param.blogImage.gatsbyImageData}
                            alt={param.title}
                            loading="lazy"
                        />
                    </div>
                    <div>
                        <p className="text-p3 text-clr-primary text-fw-medium">
                            <Link
                                className="text-clr-primary text-decor-none"
                                to={`/blog/${param.slug}/`}
                            >
                                {param.title}
                            </Link>
                        </p>
                        <div className="blog-hero__grid-item-right-col-wrap__content-text flex flex-ai-c">
                            <p className="text-p5 text-fw-regular text-clr-secondary">
                                {param.author}
                            </p>
                            <p className="text-p5 text-fw-regular text-clr-secondary">
                                {param.readTime}
                            </p>
                        </div>
                    </div>
                </div>
            )
    );
};

export default BlogsHeroGridV2;
