import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import BlogsHeroGridV2 from "./BlogsHeroGridV2";

const BlogsHeroGrid = ({ blogsData }) => {
    return (
        <div className="blogs-hero__grid">
            <div className="blogs-hero__grid-item-left-col">
                <div className="blogs-hero__grid-item__blog-hero-img">
                    <GatsbyImage
                        image={blogsData[0].blogImage.gatsbyImageData}
                        alt={"blog-hero-img"}
                        loading="lazy"
                    />
                </div>
                <div className="blogs-hero__grid-item__blog-hero-category flex flex-a-i-c">
                    {blogsData[0].categories.map((category, i) => (
                        <Link
                            key={i}
                            className="category-type text-decor-none text-p6 text-fw-medium text-clr-primary "
                            to={`/blog/${category.slug}`}
                        >
                            {category.categoryType}
                        </Link>
                    ))}
                </div>
                <h2 className="text-h2 blogs-hero__grid-item__blog-hero-title text-clr-primary text-fw-medium">
                    <Link
                        className="text-clr-primary text-decor-none"
                        to={`/blog/${blogsData[0].slug}/`}
                    >
                        {blogsData[0].title}
                    </Link>
                </h2>

                <p className="text-p4 blogs-hero__grid-item__blog-hero-description text-clr-secondary text-fw-regular">
                    {blogsData[0].description?.description}
                </p>
                <div className="text-p4 blogs-hero__grid-item__blog-hero-info-wrap flex flex-a-i-c">
                    <p className="text-p6 text-clr-secondary text-fw-regular">
                        {blogsData[0].readTime}
                    </p>
                    <p className="text-p6 text-clr-secondary text-fw-regular">
                        {blogsData[0].author}
                    </p>
                </div>
            </div>
            <div className="blog-hero__grid-item-right-col">
                <div className="blog-hero__grid-item-right-col-wrap">
                    <BlogsHeroGridV2 featuredData={blogsData} />
                </div>
            </div>
        </div>
    );
};

export default BlogsHeroGrid;
